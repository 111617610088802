<template>
  <div class="UserAward_wrapper">
    <div class="select_wrap">
      <div>
        <span>请输入ID：</span>
        <el-input
          class="ml10"
          :clearable="true"
          placeholder="请输入ID搜索 "
          v-model="UserBonusInfo.userId"
        ></el-input>
      </div>
      <div>
        <span>请输入昵称：</span>
        <el-input
          class="ml10"
          :clearable="true"
          placeholder="请输入用户昵称搜索 "
          v-model="UserBonusInfo.nickname"
        ></el-input>
      </div>
      <div>
        <span>邀请人状态：</span>
        <el-select v-model="UserBonusInfo.bsUserStatus" placeholder="请选择" clearable>
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <el-button type="primary" class="ml10" icon="el-icon-search" @click="searchPage">搜索</el-button>
      <el-button type="success" class="ml10" icon="el-icon-download" @click="exportData">导出表格</el-button>
    </div>
    <el-table class="mt20" :data="UserBonusList" border stripe size="mini" style="width: 100%">
      <el-table-column prop="beShareUserId" label="用户ID"></el-table-column>
      <el-table-column prop="nickname" label="用户昵称"></el-table-column>
      <el-table-column prop="type" label="奖励类型">
        <template slot-scope="scope">
          <el-tag type="primary" v-if="scope.row.type=='view'">浏览</el-tag>
          <el-tag type="primary" v-if="scope.row.type=='fission'">裂变</el-tag>
          <el-tag type="primary" v-if="scope.row.type=='enrollment'">报名</el-tag>
          <el-tag type="primary" v-if="scope.row.type=='card'">售卡</el-tag>
          <el-tag type="primary" v-if="scope.row.type=='live_view'">浏览</el-tag>
          <el-tag type="primary" v-if="scope.row.type=='live_order_cus'">直播签单客户</el-tag>
          <el-tag type="primary" v-if="scope.row.type=='live_self_order'">直播自签单</el-tag>
          <el-tag type="primary" v-if="scope.row.type=='live_other_order'">直播贡献单</el-tag>
          <el-tag type="primary" v-if="scope.row.type=='live_self_order_change'">直播自签转单</el-tag>
          <el-tag type="primary" v-if="scope.row.type=='live_other_order_change'">直播贡献转单</el-tag>
          <el-tag type="primary" v-if="scope.row.type=='process_self_order'">过程自签单</el-tag>
          <el-tag type="primary" v-if="scope.row.type=='process_devote_order'">过程贡献单</el-tag>
          <el-tag type="primary" v-if="scope.row.type=='seckill_user'">秒杀用户</el-tag>
          <el-tag type="primary" v-if="scope.row.type=='thumb'">集赞</el-tag>
          <el-tag type="primary" v-if="scope.row.type=='appointment'">直播预约</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="奖励金额"></el-table-column>
      <el-table-column prop="availableAmount" label="账户余额">
        <template slot-scope="scope">
          <span>{{scope.row.availableAmount || 0}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="userId" label="邀请人ID"></el-table-column>
      <el-table-column prop="bsNickname" label="邀请人昵称"></el-table-column>
      <el-table-column prop="bsUserStatus" label="邀请人状态">
        <template slot-scope="scope">
          <el-tag type="primary" v-if="scope.row.bsUserStatus=='normal'">正常</el-tag>
          <el-tag type="warning" v-if="scope.row.bsUserStatus=='second'">超出每秒预设的阈值</el-tag>
          <el-tag type="warning" v-if="scope.row.bsUserStatus=='hour'">超出每小时预设的阈值</el-tag>
          <el-tag type="warning" v-if="scope.row.bsUserStatus=='day'">超出每天预设的阈值</el-tag>
          <el-tag type="warning" v-if="scope.row.bsUserStatus=='qcloud'">腾讯云判断异常</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="tradeNo" label="交易流水号"></el-table-column>
      <el-table-column prop="createTime" label="到款时间"></el-table-column>
    </el-table>
    <div class="tab-page">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="UserBonusInfo.page"
        :page-size="10"
        layout="total, prev, pager, next, jumper"
        :total="UserBonusTotal"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import StatisticsAjax from "@/utils/https/modules/Statistics.request.js";
export default {
  name: "UserAward", // 数据统计-营销数据-用户奖励
  components: {},
  props: {
    activityID: String,
  },
  data() {
    return {
      idInput: "",
      options: [
        { value: "normal", label: "正常" },
        { value: "second", label: "超出每秒预设的阈值" },
        { value: "hour", label: "超出每小时预设的阈值" },
        { value: "day", label: "超出每天预设的阈值" },
        { value: "qcloud", label: "腾讯云判断异常" },
      ],
      statusoptions: [
        { value: "active", label: "未核销" },
        { value: "finish", label: "已核销" },
      ],
      value: "",
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],

      UserBonusInfo: {
        actId: 0,
        bsUserStatus: '',
        nickname: "",
        page: 1,
        size: 10,
        userId: "",
      },
      UserBonusList: [],
      UserBonusTotal: 0,
      bonusTypeOptions: [
        { value: "view", label: "浏览" },
        { value: "fission", label: "裂变" },
        { value: "enrollment", label: "报名" },
        { value: "card", label: "售券" },
        { value: "live_view", label: "进直播间客户" },
        { value: "live_order_cus", label: "直播签单客户" },
        { value: "live_self_order", label: "直播自签单" },
        { value: "live_other_order", label: "直播贡献单" },
        { value: "live_self_order_change", label: "直播自签转单" },
        { value: "live_other_order_change", label: "直播贡献转单" },
        { value: "process_self_order", label: "过程自签单" },
        { value: "process_devote_order", label: "过程贡献单" },
        { value: "seckill_user", label: "秒杀客户" },
        { value: "thumb", label: "集赞客户" },
        { value: "appointment", label: "直播预约" },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 用户奖励列表页码请求
    handleCurrentChange(val) {
      this.UserBonusInfo.page = val;
      this.getQueryUserBonusList();
    },
    searchPage() {
      this.UserBonusInfo.page = 1;
      this.getQueryUserBonusList();
    },
    // 查询用户奖励列表
    async getQueryUserBonusList() {
      try {
        this.UserBonusInfo.actId = this.activityID;
        const {
          data: { list, total },
        } = await StatisticsAjax.getQueryUserBonusList(this.UserBonusInfo);
        this.UserBonusList = list;
        this.UserBonusTotal = total;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 导出用户奖励列表
    async exportData() {
      try {
        const data = await StatisticsAjax.getExportUserBonusList(
          this.UserBonusInfo
        );
        const link = document.createElement("a");
        let blob = new Blob([data.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "用户奖励统计.xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
  created() {
    this.getQueryUserBonusList();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.UserAward_wrapper {
  .select_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    div {
      height: 40px;
      line-height: 40px;
      margin: 0 10px;
      .el-input {
        width: 200px;
      }
    }
  }
  .tab-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    // text-align: right;
    margin: 10px 0;
  }
}
</style>
