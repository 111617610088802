var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "UserAward_wrapper" },
    [
      _c(
        "div",
        { staticClass: "select_wrap" },
        [
          _c(
            "div",
            [
              _c("span", [_vm._v("请输入ID：")]),
              _c("el-input", {
                staticClass: "ml10",
                attrs: { clearable: true, placeholder: "请输入ID搜索 " },
                model: {
                  value: _vm.UserBonusInfo.userId,
                  callback: function($$v) {
                    _vm.$set(_vm.UserBonusInfo, "userId", $$v)
                  },
                  expression: "UserBonusInfo.userId"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c("span", [_vm._v("请输入昵称：")]),
              _c("el-input", {
                staticClass: "ml10",
                attrs: { clearable: true, placeholder: "请输入用户昵称搜索 " },
                model: {
                  value: _vm.UserBonusInfo.nickname,
                  callback: function($$v) {
                    _vm.$set(_vm.UserBonusInfo, "nickname", $$v)
                  },
                  expression: "UserBonusInfo.nickname"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c("span", [_vm._v("邀请人状态：")]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择", clearable: "" },
                  model: {
                    value: _vm.UserBonusInfo.bsUserStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.UserBonusInfo, "bsUserStatus", $$v)
                    },
                    expression: "UserBonusInfo.bsUserStatus"
                  }
                },
                _vm._l(_vm.options, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "ml10",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.searchPage }
            },
            [_vm._v("搜索")]
          ),
          _c(
            "el-button",
            {
              staticClass: "ml10",
              attrs: { type: "success", icon: "el-icon-download" },
              on: { click: _vm.exportData }
            },
            [_vm._v("导出表格")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "mt20",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.UserBonusList,
            border: "",
            stripe: "",
            size: "mini"
          }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "beShareUserId", label: "用户ID" }
          }),
          _c("el-table-column", {
            attrs: { prop: "nickname", label: "用户昵称" }
          }),
          _c("el-table-column", {
            attrs: { prop: "type", label: "奖励类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.type == "view"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("浏览")
                        ])
                      : _vm._e(),
                    scope.row.type == "fission"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("裂变")
                        ])
                      : _vm._e(),
                    scope.row.type == "enrollment"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("报名")
                        ])
                      : _vm._e(),
                    scope.row.type == "card"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("售卡")
                        ])
                      : _vm._e(),
                    scope.row.type == "live_view"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("浏览")
                        ])
                      : _vm._e(),
                    scope.row.type == "live_order_cus"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("直播签单客户")
                        ])
                      : _vm._e(),
                    scope.row.type == "live_self_order"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("直播自签单")
                        ])
                      : _vm._e(),
                    scope.row.type == "live_other_order"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("直播贡献单")
                        ])
                      : _vm._e(),
                    scope.row.type == "live_self_order_change"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("直播自签转单")
                        ])
                      : _vm._e(),
                    scope.row.type == "live_other_order_change"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("直播贡献转单")
                        ])
                      : _vm._e(),
                    scope.row.type == "process_self_order"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("过程自签单")
                        ])
                      : _vm._e(),
                    scope.row.type == "process_devote_order"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("过程贡献单")
                        ])
                      : _vm._e(),
                    scope.row.type == "seckill_user"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("秒杀用户")
                        ])
                      : _vm._e(),
                    scope.row.type == "thumb"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("集赞")
                        ])
                      : _vm._e(),
                    scope.row.type == "appointment"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("直播预约")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "amount", label: "奖励金额" }
          }),
          _c("el-table-column", {
            attrs: { prop: "availableAmount", label: "账户余额" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.availableAmount || 0))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "userId", label: "邀请人ID" }
          }),
          _c("el-table-column", {
            attrs: { prop: "bsNickname", label: "邀请人昵称" }
          }),
          _c("el-table-column", {
            attrs: { prop: "bsUserStatus", label: "邀请人状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.bsUserStatus == "normal"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("正常")
                        ])
                      : _vm._e(),
                    scope.row.bsUserStatus == "second"
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("超出每秒预设的阈值")
                        ])
                      : _vm._e(),
                    scope.row.bsUserStatus == "hour"
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("超出每小时预设的阈值")
                        ])
                      : _vm._e(),
                    scope.row.bsUserStatus == "day"
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("超出每天预设的阈值")
                        ])
                      : _vm._e(),
                    scope.row.bsUserStatus == "qcloud"
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("腾讯云判断异常")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "tradeNo", label: "交易流水号" }
          }),
          _c("el-table-column", {
            attrs: { prop: "createTime", label: "到款时间" }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tab-page" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.UserBonusInfo.page,
              "page-size": 10,
              layout: "total, prev, pager, next, jumper",
              total: _vm.UserBonusTotal
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }